// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//

$primary:                                                   #B5954B!default;
$success:                                                   #B5954B!default;

$dark : #1A1919!default;

body {

    background-color: #1E1C1A!important;
}



