
// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: .875rem;

/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/raleway-v28-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/raleway-v28-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway-v28-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway-v28-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway-v28-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway-v28-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/raleway-v28-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/raleway-v28-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway-v28-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway-v28-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway-v28-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway-v28-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/raleway-v28-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/raleway-v28-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway-v28-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway-v28-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway-v28-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway-v28-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* prata-regular - latin */
@font-face {
  font-family: 'Prata';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/prata-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/prata-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/prata-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/prata-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/prata-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/prata-v18-latin-regular.svg#Prata') format('svg'); /* Legacy iOS */
}

$primary:                                                   #B5954B!default;
$success:                                                   #B5954B!default;

$font-family-base:                                          "Raleway", sans-serif;

// Colors
$body-color:                                                #ffffff!important;

// Avatar sizes

$avatar-width-xxl :                                         10rem;
$avatar-height-xxl:                                         10rem;



.text-light {

  font-style: normal!important;
  font-weight: 200!important;
}

.accordion-button {
    background: none!important;
}

html, body {

  background-color:#070707!important;
}

h1,h2,h3,h4,h5,h6 {

  font-family: 'Prata';
  font-style: normal;
  font-weight: 400;
 

}


.accordion-button {

  font-weight: 400!important;
}

.border-light-70 {
  border-bottom:1px solid hsla(0,0%,100%,.7)!important;
}
.border-right-light-70 {
  border-right:1px solid hsla(0,0%,100%,.4)!important;
}

.btn-outline-primary, .btn-outline-secondary {
  border-color: #B5954B;
}



.accordion-button:after {
  background-color: #B5954B!important;
  padding:4px!important;
}