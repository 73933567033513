
.hb-custom-header #mk-page-introduce,
.mk-header {
}
.hb-custom-header > div,
.mk-header-bg {
    background-color: #fff;
}
.mk-classic-nav-bg {
    background-color: #fff;
}
.master-holder-bg {
    background-color: #fff;
}
#mk-footer {
    background-color: #2d2c45;
}
#mk-boxed-layout {
    -webkit-box-shadow: 0 0 0px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 0 0px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
}
.mk-news-tab .mk-tabs-tabs .is-active a,
.mk-fancy-title.pattern-style span,
.mk-fancy-title.pattern-style.color-gradient span:after,
.page-bg-color {
    background-color: #fff;
}
.page-title {
    font-size: 20px;
     
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
}
.page-subtitle {
    font-size: 14px;
    line-height: 100%;
     
    font-size: 14px;
    text-transform: uppercase;
}
body {
    font-family: Arial, Helvetica, sans-serif;
}
body {
    font-family: Montserrat;
}
@font-face {
    font-family: "star";
    src: url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/star/font.eot");
    src: url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/star/font.eot?#iefix") format("embedded-opentype"),
        url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/star/font.woff") format("woff"), url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/star/font.ttf") format("truetype"),
        url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/star/font.svg#star") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "WooCommerce";
    src: url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/woocommerce/font.eot");
    src: url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/woocommerce/font.eot?#iefix") format("embedded-opentype"),
        url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/woocommerce/font.woff") format("woff"),
        url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/woocommerce/font.ttf") format("truetype"),
        url("https://www.postvb.nl/wp-content/themes/jupiter/assets/stylesheet/fonts/woocommerce/font.svg#WooCommerce") format("svg");
    font-weight: normal;
    font-style: normal;
}
.grecaptcha-badge {
    visibility: hidden !important;
}
.mk-fancy-title.color-single span,
.mk-fancy-title.color-single p {
    color: inherit;
}
.mk-slideshow-box-items {
    height: 0;
    max-height: 1px;
}
.mk-tooltip--text:after {
    left: 128px !important;
}
.mk-tooltip .mk-font-icons .font-icon.mk-size-small svg {
    fill: #fff;
}
.mk-tooltip {
    float: right;
}
.mk-tooltip--text {
    bottom: 31px !important;
    left: -125px !important;
}
.mk-tooltip .font-icon,
.mk-tooltip--link {
    cursor: pointer;
}
#cookie-notice {
    line-height: 35px;
}
.cn-button.wp-default {
    border: 2px solid #fff !important;
    background: none;
    color: #fff;
    text-shadow: none;
    border-radius: 0;
    padding: 12px 12px;
}
#mk-tabs.simple-style .wpb_tabs_nav {
    background-color: #fff;
}
@media handheld, only screen and (max-width: 767px) {
    #header-section {
        min-height: 300px !important;
    }
    .mk-list-styles ul li {
        margin: 0 0 6px -10px !important;
    }
    #mk-tabs.wpb_content_element {
        margin-bottom: 0;
    }
    .wpb_tabs_nav .ui-state-default {
        margin-top: 5px !important;
    }
    #gemiddeldeuitkering_ondergrafiekOB,
    #gemiddeldeuitkering_ondergrafiek {
        display: none;
    }
    .mk-button-container .mk-button {
        width: 100%;
    }
    #mk-tabs .vc_column_container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .mk-shape-divider {
        display: none;
    }
    .mk-button--size-large,
    .wpcf7-form .wpcf7-submit {
        width: 100% !important;
    }
    .vc_column-inner {
        padding-left: 0 !important;
    }
    .mk-page-section .mk-skip-to-next {
        display: none !important;
    }
}
.trans-header .bg-true.mk-header:not(.a-sticky) .mk-header-inner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -webkit-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.mk-button-container {
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
}
.mk-button--text {
    letter-spacing: 1px !important;
    line-height: 1.33;
}
.c3-legend-item-Verwachting-op-basis-van-historisch-rendement-Post line {
    stroke: #d10080 !important;
}
.c3-tooltip .c3-tooltip-name-Verwachting.op.basis.van.historisch.rendement.Post .name span {
    background-color: #d10080 !important;
}
.c3-target-Verwachting-op-basis-van-historisch-rendement-Post .c3-shape {
    stroke: #d10080 !important;
}
.c3-circles-Verwachting-op-basis-van-historisch-rendement-Post .c3-circle {
    fill: #d10080 !important;
}
.c3-legend-item-Netto-inleg line {
    stroke: #34334f !important;
}
.c3-tooltip .c3-tooltip-name-Netto.inleg td > span {
    background-color: #34334f !important;
}
.c3-target-Netto-inleg .c3-shape {
    stroke: #34334f !important;
}
.c3-circles-Netto-inleg .c3-circle {
    fill: #34334f !important;
}
.c3-legend-item-Verwachting-op-basis-van-AFM-rekenmethodiek line {
    stroke: #7f7f7f !important;
}
.c3-tooltip .c3-tooltip-name-Verwachting.op.basis.van.AFM.rekenmethodiek td > span {
    background-color: #7f7f7f !important;
}
.c3-target-Verwachting-op-basis-van-AFM-rekenmethodiek .c3-shape {
    stroke: #7f7f7f !important;
}
.c3-circles-Verwachting-op-basis-van-AFM-rekenmethodiek .c3-circle {
    fill: #7f7f7f !important;
}
.c3-legend-item-Uitkering-op-basis-van-rente line {
    stroke: #7f7f7f !important;
}
.c3-tooltip .c3-tooltip-name-Uitkering.op.basis.van.rente td > span {
    background-color: #7f7f7f !important;
}
.c3-target-Uitkering-op-basis-van-rente .c3-shape {
    stroke: #7f7f7f !important;
}
.c3-circles-Uitkering-op-basis-van-rente .c3-circle {
    fill: #7f7f7f !important;
}
.c3-legend-item-Uitkering-per-periode line {
    stroke: #ffbde6 !important;
}
.c3-tooltip .c3-tooltip-name-Uitkering.per.periode td > span {
    background-color: #ffbde6 !important;
}
.c3-target-Uitkering-per-periode .c3-shape {
    stroke: #ffbde6 !important;
}
.c3-bars-Uitkering-per-periode .c3-bar {
    fill: #ffbde6 !important;
}
.c3-legend-item-Waardeverloop line {
    stroke: #d0278f !important;
}
.c3-tooltip-name-Waardeverloop .name span {
    background-color: #d0278f !important;
}
.c3-target-Waardeverloop .c3-shape {
    stroke: #d0278f !important;
}
.c3-target-Waardeverloop .c3-shape {
    stroke: #d0278f !important;
}
.c3-circles-Waardeverloop .c3-circle {
    fill: #d0278f !important;
}
.c3-line-Drawdown-Post {
    stroke: #d0278f !important;
}
.c3-target-Drawdown-Post .c3-shape {
    stroke: #d0278f !important;
}
.c3-legend-item-Drawdown-Post line {
    stroke: #d0278f !important;
}
.c3-tooltip-name-Drawdown.Post .name span {
    background-color: #d0278f !important;
}
.c3-circles-Drawdown-Post .c3-circle {
    fill: #d0278f !important;
}
.c3-line-Drawdown-Benchmark {
    stroke: #7f7f7f !important;
}
.c3-target-Drawdown-Benchmark .c3-shape {
    stroke: #7f7f7f !important;
}
.c3-legend-item-Drawdown-Benchmark line {
    stroke: #7f7f7f !important;
}
.c3-tooltip-name-Drawdown.Benchmark .name span {
    background-color: #7f7f7f !important;
}
.c3-circles-Drawdown-Benchmark .c3-circle {
    fill: #7f7f7f !important;
}
.c3-target-Resultaat .c3-bar {
    fill: #34334f !important;
}
.c3-legend-item-Resultaat line {
    stroke: #34334f !important;
}
.c3-tooltip-name-Resultaat .name span {
    background-color: #34334f !important;
}
.c3-target-Resultaat .c3-bar {
    fill: #34334f !important;
}
.c3-legend-item-Resultaat line {
    stroke: #34334f !important;
}
.c3-tooltip-name-Resultaat .name span {
    background-color: #34334f !important;
}
table td {
    text-align: left;
    padding: 12px;
}
.wpb_tab table td {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.wpb_tab {
    padding: 12px !important;
}
#mk-tabs.simple-style .wpb_tabs_nav {
    border-bottom: 5px solid #eee;
}
#mk-tabs.simple-style .wpb_tabs_nav a {
    border-bottom-width: 5px;
    margin-bottom: -4px;
}
#mk-tabs.simple-style .wpb_tabs_nav .ui-state-active a {
    border-bottom-color: #d0278f;
}
.mk-text-block p,
.mk-fancy-title {
    text-align: justify;
}
.mk-post-cat {
    display: none !important;
}
span.wpcf7-list-item {
    margin-left: 0em;
}
.mk-toggle.fancy-style .mk-toggle-title:hover {
    background-color: #d0278f;
    color: white;
}
#mk-breadcrumbs .mk-breadcrumbs-inner {
    padding: 0px 12px 8px;
}
.page-subtitle,
.page-title,
.filter-faq {
    display: none;
}
#mk-breadcrumbs .mk-breadcrumbs-inner.light-skin {
    float: left;
    margin-left: 10px;
}
form .form-half {
    width: 48%;
    float: left;
}
form .form-half.second {
    margin-left: 4%;
}
form .form-half input,
.wpcf7-form .wpcf7-textarea,
form .form-half input:active,
form .form-half input:focus {
    width: 100%;
    background-color: transparent;
    border: 0;
    border-bottom: solid 3px white;
    color: white;
    margin-top: 15px;
}
form .form-half input::placeholder,
.wpcf7-form .wpcf7-textarea::placeholder {
    color: white;
    background-color: transparent;
}
.wpcf7-form .wpcf7-submit {
    margin-top: 20px;
    background-color: #d0278f;
    border-radius: 3px;
}
.wpcf7-form .wpcf7-submit:hover {
    background-color: #d10080;
}
.mk-fancy-title.fancyextra {
    margin: 0;
}
@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}
.wpcf7-form input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}
.fancyhref {
    color: white;
}
.white-contact .wpcf7-list-item-label {
    color: white;
    font-size: 90%;
}
.footer-text a:hover {
    color: white !important;
}
.footer-text a {
    color: white !important;
}
.contact-href {
    color: #d0278f !important;
}
.contact-href:hover {
    text-decoration: underline;
}
input.homescreen,
form select.homescreen {
    color: white !important;
    background-color: #d10080 !important;
    border: 0 !important;
    padding: 10px !important;
    width: -webkit-fill-available;
    margin-bottom: 13px;
}
form select.homescreen#field_risco {
    margin-bottom: 45px;
}
form.homescreen label {
    font-size: 12px;
    color: white;
}
input.homescreen::-webkit-input-placeholder {
    color: white;
}
input.homescreen::-moz-placeholder {
    color: white;
}
input.homescreen::-ms-placeholder {
    color: white;
}
input.homescreen::placeholder {
    color: white;
}
form.homescreen input#submit {
    background-color: #7f7f7f !important;
    border: 0;
    height: 43px;
    width: 100%;
    color: white;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url(https://www.postvb.nl/wp-content/themes/jupiter-child/assets/images/right-arrow.png) !important;
    letter-spacing: 4px;
}
form.homescreen select.homescreen {
    background-image: url(https://www.postvb.nl/wp-content/themes/jupiter-child/assets/images/selectbox-arrow.png) !important;
    min-width: unset;
    max-width: unset;
}
.c3-chart-line.c3-target.c3-target-Uitkering-op-basis-van-rente {
    stroke-dasharray: 20, 10;
}
.c3-axis-y .tick,
.c3-axis-x .tick {
    fill: black !important;
}
.c3-ygrid {
    stroke-dasharray: 0 !important;
    stroke: lightgrey !important;
}
.c3-axis.c3-axis-x .tick line {
    stroke: none !important;
}
.results {
    background-color: #7f7f7f !important;
    padding: 15px;
    color: white;
}
.c3-tooltip td {
    text-align: left;
}
.c3 svg {
    font: unset !important;
}
.smaller.button a {
    padding: 14px 14px;
}
.mk-blog-grid-item .featured-image {
    max-height: 190px;
}
#periodieke_inleg:disabled {
    background-color: gray !important;
}
#periodieke_inlegOB:disabled {
    background-color: gray !important;
}
#switch {
    color: white;
    position: absolute;
    right: 33px;
    bottom: 86px;
}
#switch2 {
    color: white;
    position: absolute;
    right: 33px;
    bottom: 43px;
}
i.fas.fa-exchange-alt {
    margin-left: 10px;
}
.input-euro {
    position: relative;
}
.input-euro input {
    padding-left: 18px !important;
}
.input-euro:before {
    position: absolute;
    top: 29px;
    left: -142px;
    content: "€";
    color: white;
}
.input-euro.two:before {
    position: absolute;
    top: -7px;
    left: 4px;
    content: "€";
    color: white;
}
.c3-legend-item {
    fill: black !important;
}
table.c3-tooltip td {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
#mk-footer-navigation ul li a {
    margin: 0 !important;
    padding: 25px 12px 20px !important;
}


.c3-chart-line.c3-target.c3-target-Uitkering-op-basis-van-rente {
    stroke-dasharray: 20, 10;
}
.c3-axis-y .tick,
.c3-axis-x .tick {
    fill: black !important;
}
.c3-ygrid {
    stroke-dasharray: 0 !important;
    stroke: lightgrey !important;
}
.c3-axis.c3-axis-x .tick line {
    stroke: none !important;
}
.results {
    background-color: #7f7f7f !important;
    padding: 15px;
    color: white;
}
.c3-tooltip td {
    text-align: left;
}
.c3 svg {
    font: unset !important;
}
.smaller.button a {
    padding: 14px 14px;
}
.mk-blog-grid-item .featured-image {
    max-height: 190px;
}
#periodieke_inleg:disabled {
    background-color: gray !important;
}
#periodieke_inlegOB:disabled {
    background-color: gray !important;
}
#switch {
    color: white;
    position: absolute;
    right: 33px;
    bottom: 86px;
}
#switch2 {
    color: white;
    position: absolute;
    right: 33px;
    bottom: 43px;
}
i.fas.fa-exchange-alt {
    margin-left: 10px;
}
.input-euro {
    position: relative;
}
.input-euro input {
    padding-left: 18px !important;
}
.input-euro:before {
    position: absolute;
    top: 29px;
    left: -142px;
    content: "€";
    color: white;
}
.input-euro.two:before {
    position: absolute;
    top: -7px;
    left: 4px;
    content: "€";
    color: white;
}
.c3-legend-item {
    fill: black !important;
}
table.c3-tooltip td {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
#mk-footer-navigation ul li a {
    margin: 0 !important;
    padding: 25px 12px 20px !important;
}


.c3 svg {
    font: 12px sans-serif;
}
.c3 line,
.c3 path {
    fill: none;
    stroke: #d3d3d3;
}
.c3 text {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.c3-bars path,
.c3-event-rect,
.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid {
    shape-rendering: crispEdges;
}
.c3-chart-arc path {
    stroke: #fff;
}
.c3-chart-arc text {
    fill: #fff;
    font-size: 13px;
}
.c3-grid line {
    stroke: #aaa;
}
.c3-grid text {
    fill: #aaa;
}
.c3-xgrid,
.c3-ygrid {
    stroke-dasharray: 3 3;
}
.c3-text.c3-empty {
    fill: grey;
    font-size: 2em;
}
.c3-line {
    stroke-width: 3px;
}
.c3-circle._expanded_ {
    stroke-width: 1px;
    stroke: #fff;
}
.c3-selected-circle {
    fill: #fff;
    stroke-width: 2px;
}
.c3-bar {
    stroke-width: 0;
}
.c3-bar._expanded_ {
    fill-opacity: 0.75;
}
.c3-target.c3-focused {
    opacity: 1;
}
.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
    stroke-width: 5px;
}
.c3-target.c3-defocused {
    opacity: 0.3 !important;
}
.c3-region {
    fill: #4682b4;
    fill-opacity: 0.1;
}
.c3-brush .extent {
    fill-opacity: 0.1;
}
.c3-legend-item {
    font-size: 12px;
    fill: #d3d3d3;
}
.c3-legend-item-hidden {
    opacity: 0.15;
}
.c3-legend-background {
    opacity: 0;
    stroke-width: 1;
}
.c3-tooltip-container {
    z-index: 10;
}
.c3-tooltip {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #fff;
    empty-cells: show;
    -webkit-box-shadow: 7px 7px 12px -9px #777;
    -moz-box-shadow: 7px 7px 12px -9px #777;
    box-shadow: 7px 7px 12px -9px #777;
    opacity: 0.9;
    color: #000;
}
.c3-tooltip tr {
    border: 1px solid #ccc;
}
.c3-tooltip th {
    background-color: #aaa;
    font-size: 14px;
    padding: 2px 5px;
    text-align: left;
    color: #fff;
}
.c3-tooltip td {
    font-size: 13px;
    padding: 3px 6px;
    background-color: #fff;
    border-left: 1px dotted #999;
}
.c3-tooltip td > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
}
.c3-tooltip td.value {
    text-align: right;
}
.c3-area {
    stroke-width: 0;
    opacity: 0.2;
}
.c3-chart-arcs-title {
    dominant-baseline: middle;
    font-size: 1.3em;
}
.c3-chart-arcs .c3-chart-arcs-background {
    fill: #e0e0e0;
    stroke: none;
}
.c3-chart-arcs .c3-chart-arcs-gauge-unit {
    fill: #000;
    font-size: 16px;
}
.c3-chart-arcs .c3-chart-arcs-gauge-max,
.c3-chart-arcs .c3-chart-arcs-gauge-min {
    fill: #777;
}
.c3-chart-arc .c3-gauge-value {
    fill: #000;
}
.c3-axis-x .tick,
.c3-axis-y .tick {
    fill: #d3d3d3;
}
